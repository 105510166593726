<template>
	<footer>
		<div class="primary-container">
			<section class="housekeeping">
				<section>
					<h3><n-link :to="localePath('account')">My Account</n-link></h3>
					<h3><n-link :to="localePath('account-orders')">Order History</n-link></h3>
					<n-link :to="localePath('account-settings')" v-if="false">Settings</n-link>
				</section>
				<section>
					<h3>Contact Information</h3>
					<p>
						<b>Contact:</b> {{ $t('contact.name') }}
					</p>
					<p>
						<b>Phone:</b> {{ $t('contact.phone') }}
					</p>
					<p v-show="$store.state.session.batch_number==1">
						<b>Email:</b> <a :href="'mailto:' + $t('contact.email')">{{ $t('contact.email') }}</a>
					</p>
					<p v-show="$store.state.session.batch_number==2">
						<b>Email:</b> <a :href="'mailto:' + $t('contact.email2')">{{ $t('contact.email2') }}</a>
					</p>
				</section>
				<section>
					<h3>Important Information</h3>
					<n-link :to="localePath('faq')">FAQ</n-link>
					<n-link :to="localePath('privacy')">Privacy Policy</n-link>
				</section>
				<section></section>
			</section>
			<section class="copyright">
				&copy; <a href="https://www.asabrands.ie">Powered by ASA Marketing Limited / ASA Brands {{ current_year }}</a><br>
			  <div class="asa_footer_logo" title="ASA Brands">
				<a href="https://www.asabrands.ie">
					<img src="~/assets/logo-footer.png" alt="ASA Brands Items Store">
				</a>
			  </div>
			</section>
		</div>
	</footer>
</template>

<script>
	export default {
		data() {
			return {
				current_year: new Date().getFullYear()
			};
		},
		computed: {
			other_locale() {
				switch (this.$i18n.locale) {
					case 'eu': {
						return {
							code: 'us',
							title: 'US',
						};
					}
					case 'us': {
						return {
							code: 'eu',
							title: 'European',
						};
					}
				}
				return {};
			}
		}
	}
</script>

<style scoped>
	.housekeeping {
		font-size: 14px;
	}

	.housekeeping h3 {
		font-size: 16px;
		font-weight: normal;
    
	}

	.housekeeping p {
		margin: 7px 0;
	}

	.housekeeping>section {
		display: flex;
		flex-direction: column;
	}

	footer a {
		padding: 7px 0;
	}

	.copyright {
		text-align: center;
		margin: 10px;
		padding: 5px;
		font-size: 13px;
	}

	.asa_footer_logo {
	  display: inline-block;
	  padding: 12px 5px 10px 15px;
	}
	.asa_footer_logo img {
	  max-width: 100%;
	  height: 36px;
	}
	@media screen and (min-width: 768px) {
		.housekeeping {
			display: flex;
			justify-content: space-between;
		}
	}
</style>
