<template>
	<header class="paper">
		<div class="">
			<div>
				<n-link :to="$store.state.session.signed_in ? '/': '/auth'">
					<Logo />
				</n-link>
				<div class="desktop-flex">
					<div class="account">
						<div class="desktop-flex">
							<div class="column flex-center">
								<div style="font-size: 13px">
									<n-link :to="$store.state.session.signed_in ? '/': '/auth'">
										<!--<span class="orange">EMEA</span><br>
										<span class="orange">STORE</span>-->
									</n-link>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div>
				<n-link class="cart" :to="localePath('cart')" title="Cart">
					<i class="material-icons color-primary">shopping_cart</i>
					<div class="bg-interactive cart-items">{{ $store.state.cart.products.length }}</div>
				</n-link>
				<div class="account color-primary" @click="toggle_menu">
					<i class="material-icons color-primary">account_circle</i>
					<div class="desktop-flex">
						<div class="column flex-center">
							<div class="account-label" v-show="!$store.state.session.signed_in">Sign in</div>
							<div>My Account<!--{{ $store.state.global.name }}--></div>
						</div>
					</div>
				</div>
				<div class="overlay paper" v-show="show_menu" @click="toggle_menu"></div>
				<nav class="surface" v-show="show_menu" @click="toggle_menu">

					<nuxt-link :to="localePath('account-orders')">Your Orders</nuxt-link>
					<nuxt-link :to="localePath('account-addresses')">Your Addresses</nuxt-link>
					<nuxt-link :to="localePath('account-settings')" v-if="false">Settings</nuxt-link>
					<hr>
					<nuxt-link :to="localePath('account')">My Account</nuxt-link>
					<hr>
					<button class="button inline" @click="sign_out" v-if="$store.state.session.signed_in">Sign Out</button>
					<n-link class="button inline" :to="localePath('auth')" v-else>Sign In</n-link>
				</nav>
			</div>
			<form class="search" :action="localePath('search')" method="get">
				<input type="text" placeholder="Search" name="keywords" v-model="search_text">
				<button class="bg-secondary search-button"><img src="~/assets/icons/search36.png" alt="Search Icon"></button>
			</form>
		</div>
		<div class="secondary-bar">
			<button class="bg-secondary category-icon text-button" @click="toggle_category_menu">
				Categories
				<i class="material-icons" v-if="show_category_menu">keyboard_arrow_up</i>
				<i class="material-icons" v-else>keyboard_arrow_down</i>
			</button>
			<div class="category-menu paper surface" v-show="show_category_menu" @click="toggle_category_menu">
				<div>
					<div @mouseover="set_blip(0)">
						<n-link to="/search">
							All Products
						</n-link>
					</div>
					<div v-for="(category, key) in Object.values($store.state.subcategories.subcategories)" :key="key" @mouseover="set_blip(key + 1)">
						<n-link :to="'/search?subcategory=' + encodeURIComponent(category.name)" :class="{ 'color-interactive': $route.query.category == category.name }">
							{{ category.name }}
						</n-link>
					</div>
					<div class="bg-interactive blip" ref="blip"></div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import Logo from '~/components/Logo.vue';

	export default {
		components: {
			Logo
		},
		data() {
			return {
				show_menu: false,
				show_category_menu: false,
				search_text: this.$store.state.global.search_text,
			};
		},
		methods: {
			toggle_menu() {
				this.show_menu=!this.show_menu;
			},
			toggle_category_menu() {
				this.show_category_menu=!this.show_category_menu;
			},
			async sign_out() {
				const response=await fetch(
					`${this.$store.state.global.api_path}?q=/authentication/sign_out`,
					{
						method: 'post',
						cache: 'no-cache',
						body: JSON.stringify({
							"access_token": this.$store.state.session.access_token,
							"refresh_token": this.$store.state.session.refresh_token,
						})
					}
				);
				this.$store.commit('session/sign_out');
				this.$router.push('/auth');
			},
			set_blip(offset) {
				this.$refs.blip.style.top=(offset*32)+'px';
				this.$refs.blip.style.visibility='visible';
			},
		},
		async created() {
			if(process.browser) {
				const data=localStorage.getItem('subcategories');
				if(data) {
					const json=JSON.parse(data);
					this.$store.commit(`subcategories/set`,json);
				}
				const json=await this.oa_fetch(`webshop/subcategories`);
				if(json.errors) {
					return;
				}
				if(json.hash!==this.$store.state.subcategories.hash) {
					this.$store.commit('subcategories/set',{hash: json.hash,subcategories: json.subcategories});
				}
				if(this.$route.query.category) {
					Object.values(this.$store.state.subcategories.subcategories).forEach((category,index) => {
						if(this.$route.query.category==category.name) {
							this.set_blip(index+1);
						}
					});
				}
			}
		},
	}
</script>

<style scoped>
	.raw_underline {
		text-decoration: underline;
	}

	.orange {
		color: #FA582D;
	}

	img {
		height: 36px;
	}

	header {
		width: 100%;
		z-index: 50;
	}

	header>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header>div>div,
	header>div>form {
		display: flex;
	}

	header>div>div:nth-child(1) {
		order: 1;
		width: 50%;
	}

	header>div>div:nth-child(2) {
		order: 3;
		width: 50%;
	}

	.secondary-bar {
		position: relative;
	}

	.category-icon i {
		font-size: 32px;
		margin-top: 4px;
	}

	.category-icon:active {
		opacity: 1;
	}

	.category-menu>div {
		position: relative;
	}

	.category-menu a {
		display: block;
		line-height: 32px;
		padding-left: 16px;
	}

	.category-menu .blip {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 2px;
		height: 24px;
		margin-top: 5px;
		transition: top .2s ease-in-out;
		visibility: hidden;
	}

	nav {
		display: flex;
		flex-direction: column;
		right: 10px;
		top: 55px;
		min-width: 300px;
		padding: 16px;
		position: absolute;
		z-index: 2147483646;
	}

	nav a {
		display: inline-flex;
		padding: 10px;
	}

	.cart,
	.account {
		display: flex;
		cursor: pointer;
		padding: 12px;
	}

	.account {
		z-index: 2147483646;
	}

	.cart div {
		text-align: center;
	}

	.cart-items {
		border-radius: 10px;
		padding: 0 6px;
		position: absolute;
		right: 8px;
		top: calc(100% - 24px);
	}

	.account-label {
		font-weight: bold;
	}

	.search {
		order: 4;
		flex-grow: 2;
		padding: 12px;
	}

	.search-button {
		border-radius: 0 2px 2px 0;
		box-shadow: none;
		padding: 1px;
		min-width: 38px;
		width: 38px;
		height: 38px;
	}



	@media (min-width: 768px) {
		.search {
			order: 2;
			margin: 0 20px;
		}

		header>div>div:nth-child(1),
		header>div>div:nth-child(2) {
			width: auto;
		}
	}
</style>