export const state = () => ({
	name: "Rubrik",
	is_dev: process.env.NODE_ENV == 'development',
	api_path: 'https://' + (process.env.NODE_ENV == 'development' ? 'dev-api.asamarketing.ie' : 'api.asagroup.ie'),
	search_text: "",
});

export const mutations = {
	update_search(state, search_text) {
		state.search_text = search_text;
	}
};
