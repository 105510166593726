/* eslint-disable no-shadow */
export const state = () => ({
	products: [],
	order_id: null,
	vat_rate: 0,
	vat_number: '',
	same_address: true,
	desired_delivery_date: null,
	delivery_note:'',
	delivery_address: {
		company_name: null,
		address1: null,
		address2: null,
		address3: null,
		address4: null,
		town: null,
		county: null,
		postcode: null,
		country: null,
	},
	contact_details: {
		given_name: null,
		surname: null,
		number: null,
		email: null,
	},
	billing_address: {
		company_name: null,
		address1: null,
		address2: null,
		address3: null,
		address4: null,
		town: null,
		county: null,
		postcode: null,
		country: null,
	},
	billing_contact_details: {
		given_name: null,
		surname: null,
		number: null,
		email: null,
	},
	carriage_method: null,
	carriage_total: 0,
	carriage_message: '',
	vat_message: '',
	product_subtotal: 0,
	net_total: 0,
	vat_total: 0,
	gross_total: 0,
	paid: false
});

export const mutations = {
	update_total_price(state) {
		state.net_total = parseFloat(state.product_subtotal) + parseFloat(state.carriage_total);
		state.vat_total += Math.round(((state.carriage_total) * (state.vat_rate / 100)) * 100) / 100;
		state.gross_total = state.net_total + state.vat_total;
	},
	update_subtotal_price(state) {

		state.product_subtotal = 0;
		state.vat_total = 0;
		state.gross_total = 0;
		for (let p in state.products) {

			state.product_subtotal += state.products[p].price * state.products[p].quantity;
			state.vat_total += Math.round(((state.products[p].price * state.products[p].quantity) * (state.vat_rate / 100)) * 100) / 100;
			for (let pp in state.products[p].prints) {
				var print_price = state.products[p].prints[pp].unit_price * state.products[p].quantity;
				print_price += state.products[p].prints[pp].origination;
				print_price += (state.products[p].prints[pp].image_colours - 1) * state.products[p].prints[pp].origination_repeat;
				state.product_subtotal += print_price;
				state.vat_total += Math.round(((print_price) * (state.vat_rate / 100)) * 100) / 100;
			}
		}

		this.commit('cart/update_total_price');
	},
	paid(state) {
		state.paid = true;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	empty(state) {
		state.products = [];
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set(state, data) {
		const entries = Object.entries(data);
		for (let i = 0; i < entries.length; i += 1) {
			const [key, value] = entries[i];
			state[key] = value;
		}
	},
	add(state, product) {
		// if it is already in the cart, just update the qty
		var updated = false;
		for( var p of state.products ) {
			if( p.id == product.id ) {
				p.quantity+=product.quantity;
				updated=true;
				break;
			}
		}


		if( !updated ) state.products.push(JSON.parse(JSON.stringify(product)));
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	remove_product(state, id) {
		const index = state.products.findIndex(product => product.id === id);
		const added_to_cart = state.products[index].added_to_cart;
		const length = state.products.length;
		if (added_to_cart) {
			for (let i = 0; i < state.products.length; i++) {
				while ( ( i < state.products.length ) && state.products[i].added_to_cart == added_to_cart) {
					state.products.splice(i, 1);
				}
			}
		} else {
			state.products.splice(index, 1);
		}
		if (length !== state.products.length) {
			this.commit('cart/update_subtotal_price');
			localStorage.setItem('cart', JSON.stringify(state));
		}
	},
	remove_print(state, { product_id, location_id }) {
		const product_index = state.products.findIndex((product) => product.id === product_id);
		if (product_index == -1) {
			return;
		}
		const print_index = state.products[product_index].prints.findIndex(
			(print) => print.location_id == location_id
		);
		if (print_index == -1) {
			return;
		}
		state.products[product_index].prints.splice(print_index, 1);
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	change_quantity(state, { index, quantity }) {
		if( quantity < 1 ) quantity = 1;
		// if (quantity < state.products[index].minimum_quantity) {
		// 	quantity = state.products[index].minimum_quantity;
		// }

		// make sure we have enough stock to do this.
		var max_stock = state.products[index].stock_in - (state.products[index].stock_out);
		if( max_stock < quantity ) {
			quantity = max_stock;
		}
		

		state.products[index].quantity = parseInt(quantity, 10);
		// update the price from the price table
		
		const found_price = state.products[index].prices.reduce((value, price) => {
			//if (state.products[index].quantity >= price.min) {
				state.products[index].product_id = price.product_id;
				return price.unit_price_subtotal;
			//}
			//return value;
		}, 0);
		state.products[index].price = found_price;
		// update the print prices if there are prints
		// state.products[ index ].prints.forEach((print) => {
		// 	const prices = ( Object.keys( print.colour_prices ).length === 1 ) ? print.colour_prices[1] : print.colour_prices[print.image_colours];
		// 	let price = 'N/A';
		// 	for ( let i = 0; i < prices.length; i += 1 ) {
		// 		if ( state.products[ index ].quantity >= prices[i].min_quantity ) {
		// 			price = prices[i].price;
		// 		}
		// 	}
		// 	print.unit_price = parseFloat(parseFloat(price).toFixed(2));
		// });
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_decoration(state, { id, locations, print_methods }) {
		for (let i = 0; i < state.products.length; i += 1) {
			if (state.products[i].id === id) {
				state.products[i].locations = locations;
				state.products[i].print_methods = print_methods;
				state.products[i].decoration_updated = Math.round(new Date().getTime() / 1000);
				break;
			}
		}
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_product_prints(state, { id, prints }) {
		prints.forEach((print) => {
			print.updated = Math.round(new Date().getTime() / 1000);
		});
		for (let i = 0; i < state.products.length; i += 1) {
			if (state.products[i].id === id) {
				state.products[i].prints = prints;
				break;
			}
		}
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_delivery_address(state, in_data) {
		state.delivery_address = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_contact_details(state, in_data) {
		state.contact_details = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_billing_address(state, in_data) {
		state.billing_address = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_billing_contact_details(state, in_data) {
		state.billing_contact_details = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_carriage_total(state, in_data) {
		state.carriage_total = in_data.carriage_total;
		state.vat_rate = in_data.vat_rate;
		state.carriage_message = in_data.carriage_message ? in_data.carriage_message : '';
		state.vat_message = in_data.vat_message ? in_data.vat_message : '';
		this.commit('cart/update_subtotal_price');
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_vat_number(state, in_data) {
		state.vat_number = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	},
	set_same_address(state, in_data) {
		state.same_address = in_data;
		localStorage.setItem('cart', JSON.stringify(state));
	}
};
