export const state = () => ({
	signed_in: false,
	currency_symbol: "$",
	currency_code: "USD",
	access_token: null,
	access_token_date: null,
	refresh_token: null,
	batch_number: null
})

export const mutations = {
	set ( state, data ) {
		for ( let i in data ) {
			state[i] = data[i];
		}
	},
	sign_in ( state, data ) {
		this.commit('session/refresh', data);
		state.refresh_token = data.refresh_token;
		localStorage.setItem( 'session', JSON.stringify( state ) );
	},
	sign_out ( state ) {
		state.signed_in = false;
		state.access_token = null;
		state.access_token_date = null;
		state.refresh_token = null;
		localStorage.setItem( 'session', JSON.stringify( state ) );
	},
	set_access_token ( state, token ) {
		state.access_token = token;
		localStorage.setItem( 'session', JSON.stringify( state ) );
	},
	refresh ( state, data ) {
		state.signed_in = true;
		state.access_token = data.access_token;
		state.access_token_date = new Date();
		state.batch_number = data.batch_number;
		localStorage.setItem( 'session', JSON.stringify( state ) );
	},
}
